import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import SEO from "../components/SEO"
import Image from "../components/image"
import { css } from "@emotion/core"
import { colors, mq } from "../styles/styles"
import { RichText } from "prismic-reactjs"
import linkResolver from "../utils/linkResolver"
import { isExternalLink, isDocumentLink } from "../utils/utils"
import LocalizedLink from "../components/localizedLink"
import slugify from "slugify"
import Slider from "../components/slider"

function Integration({ data, pageContext: { locale, uid }, location }) {
  const integrationData = data.prismic.integration
  const allIntegrationsData = data.prismic.allIntegrations
  let allCategories = new Set()
  let allFeatures = new Set()
  let sliderImages = []

  integrationData.body.map(slice => {
    if (slice.__typename === "PRISMIC_IntegrationBodySlideshow") {
      slice.fields.map((field, i) => {
        const slide = {
          id: i,
          sharp: field.gallery_imageSharp,
          image: field.gallery_image,
        }
        sliderImages.push(slide)
      })
    }
  })
  integrationData._meta.tags.map(tag => {
    tag.startsWith("feature") ? allFeatures.add(tag) : allCategories.add(tag)
  })
  return (
    <>
      <SEO
        title={integrationData.meta_title ? integrationData.meta_title[0].text : ""}
        desc={integrationData.meta_description ? integrationData.meta_description[0].text : ""}
        metadata={data.site.siteMetadata}
        locale={locale}
        pathname={location.pathname}
      />

      <div css={integrationCss}>
        <section className="section is-theme2">
          <div className="container">
            <div className="columns is-centered">
              <div className="column">
                <nav
                  className="breadcrumb is-uppercase is-size-7"
                  aria-label="breadcrumbs"
                >
                  <ul>
                    <li>
                      <LocalizedLink to={"integrations"}>
                        integrations
                      </LocalizedLink>
                    </li>
                    <li className="is-active has-text-weight-semibold">
                      {RichText.asText(integrationData.title)}
                    </li>
                  </ul>
                </nav>

                <div className="columns">
                  <div className="column">
                    <div className="media">
                      <figure className="media-left">
                        <div className="image is-64x64">
                          {integrationData.main_image && (
                            <Image
                              sharp={integrationData.main_imageSharp}
                              image={integrationData.main_image}
                            />
                          )}
                        </div>
                      </figure>
                      <div className="media-content">
                        <h1 className="title is-3 has-text-weight-bold">
                          {integrationData.title &&
                            RichText.asText(integrationData.title)}
                          {integrationData.integration_for_monstock &&
                            RichText.asText(
                              integrationData.integration_for_monstock
                            )}
                        </h1>
                        <p className="subtitle is-5">
                          {integrationData.author &&
                            RichText.asText(integrationData.author)}
                          <span className="pl-2 is-size-6">
                            <i className="icon-linking has-text-white"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="column is-narrow">
                    {integrationData.get_it_button_link &&
                      isExternalLink(integrationData.get_it_button_link) && (
                        <a
                          className="button is-rounded button__primary has-text-white"
                          href={integrationData.get_it_button_link.url}
                        >
                          {integrationData.get_it_button}
                        </a>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column is-9">
                {integrationData.body.map((slice, i) => {
                  if (slice.__typename === "PRISMIC_IntegrationBodySlideshow") {
                    return (
                      <>
                        <h3 key={i} className="title is-5">
                          {RichText.asText(slice.primary.gallery_title)}
                        </h3>
                        {sliderImages.length > 1 && (
                          <Slider slidesList={sliderImages} slidesPerView="1" />
                        )}
                        {sliderImages.length <= 1 && (
                          <div className="mb-6">
                            <Image
                              sharp={sliderImages[0].sharp}
                              image={sliderImages[0].image}
                            />
                          </div>
                        )}
                      </>
                    )
                  }
                  if (
                    slice.__typename ===
                      "PRISMIC_IntegrationBodyMain_text_block" &&
                    slice.primary.main_text_is_small === false
                  ) {
                    return (
                      <div key={i} className="box__blue-bg">
                        <h3 className="title is-5">
                          {RichText.asText(slice.primary.main_text_block_title)}
                        </h3>
                        <div className="hasRichText box">
                          {slice.primary.main_text_block_content &&
                            RichText.render(
                              slice.primary.main_text_block_content,
                              linkResolver
                            )}
                        </div>
                      </div>
                    )
                  }
                })}
                <div className="simple_picture mt-5">
                  <div className="image">
                    {integrationData.body.map((slice, i) => {
                      if (
                        slice.__typename === "PRISMIC_IntegrationBodyPicture"
                      ) {
                        return (
                          <div className="simple_picture">
                            <div className="title_simple_picture title is-5">
                              {RichText.asText(slice.primary.title_name)}
                            </div>
                            <Image
                              sharp={slice.primary.pictureSharp}
                              image={slice.primary.picture}>
                            </Image>
                          </div>
                        )
                      }
                    })}
                  </div>
                </div>
              </div>
              <div className="column is-3">
                {integrationData.body.map((slice, i) => {
                  if (
                    slice.__typename === "PRISMIC_IntegrationBodyAside_details"
                  ) {
                    return (
                      <div key={i} className="box px-0 pt-0">
                        <h3 className="title is-5">
                          {RichText.asText(slice.primary.details_title)}
                        </h3>
                        <h4 className="is-size-6 mb-3">
                          {RichText.asText(slice.primary.category_title)}
                        </h4>
                        <div className="tags">
                          {Array.from(allCategories).map(category => (
                            <LocalizedLink
                              key={i + category}
                              to={`integration/tag/${slugify(
                                category
                              )}#jumpToIntegration`}
                            >
                              <span className="tag">{category}</span>
                            </LocalizedLink>
                          ))}
                        </div>

                        <h4 className="subtitle is-6">
                          {RichText.asText(slice.primary.features_title)}
                        </h4>
                        <div className="tags">
                          {Array.from(allFeatures).map(feature => (
                            <LocalizedLink
                              key={i + feature}
                              to={`integration/tag/${slugify(
                                feature
                              )}#jumpToIntegration`}
                            >
                              <span className="tag">
                                {feature.substring(8)}
                              </span>
                            </LocalizedLink>
                          ))}
                        </div>
                      </div>
                    )
                  }
                  if (slice.__typename === "PRISMIC_IntegrationBodyAdd_aside") {
                    return (
                      <div key={i} className="box px-0">
                        {slice.primary.aside_title && (
                          <h3 className="title is-5">
                            {RichText.asText(slice.primary.aside_title)}
                          </h3>
                        )}
                        {slice.primary.aside_subtitle && (
                          <h3 className="is-size-6">
                            {slice.primary.aside_subtitle}
                          </h3>
                        )}
                        {slice.fields.map(field => {
                          return (
                            <div key={i + "field"} className="box__orange-txt">
                              {field.aside_slice_content &&
                                RichText.render(
                                  field.aside_slice_content,
                                  linkResolver
                                )}
                            </div>
                          )
                        })}
                      </div>
                    )
                  }
                  if (
                    slice.__typename ===
                      "PRISMIC_IntegrationBodyMain_text_block" &&
                    slice.primary.main_text_is_small === true
                  ) {
                    return (
                      <div key={i} className="box__blue-bg">
                        <h3 className="title is-5">
                          {RichText.asText(slice.primary.main_text_block_title)}
                        </h3>
                        <div className="hasRichText box">
                          {slice.primary.main_text_block_content &&
                            RichText.render(
                              slice.primary.main_text_block_content,
                              linkResolver
                            )}
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Integration

export const integrationQuery = graphql`
  query integrationQuery($uid: String!, $locale: String!) {
    prismic {
      integration(uid: $uid, lang: $locale) {
        _meta {
          lang
          tags
          uid
          type
          id
          alternateLanguages {
            lang
            uid
          }
        }
        meta_title
        meta_description
        title
        author
        integration_for_monstock
        get_it_button
        get_it_button_link {
          _linkType
          ... on PRISMIC__ExternalLink {
            url
            target
          }
          ... on PRISMIC__Document {
            _meta {
              uid
            }
          }
        }
        main_image
        main_imageSharp {
          extension
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        body {
          ... on PRISMIC_IntegrationBodyMain_text_block {
            primary {
              main_text_block_title
              main_text_block_content
              main_text_is_small
            }
          }
          ... on PRISMIC_IntegrationBodySlideshow {
            primary {
              gallery_title
            }
            fields {
              gallery_image_id
              gallery_image
              gallery_imageSharp {
                extension
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
          ... on PRISMIC_IntegrationBodyAside_details {
            primary {
              details_title
              category_title
              features_title
            }
          }
          ... on PRISMIC_IntegrationBodyAdd_aside {
            primary {
              aside_title
              aside_subtitle
            }
            fields {
              aside_slice_id
              aside_slice_content
            }
          }
          ... on PRISMIC_IntegrationBodyPicture {
            type
            label
            primary {
              title_name
              picture
              pictureSharp {
                extension
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
        }
      }

      allIntegrations(lang: $locale) {
        edges {
          node {
            _meta {
              uid
              tags
            }
          }
        }
      }
    }
    site {
      ...SiteInformation
    }
  }
`

const integrationCss = css`

  .swiper-slide {
    .gatsby-image-wrapper {
      height: 100% !important;
    }
  }

  .breadcrumb li + li::before {
    color: ${colors.blue};
    content: "›";
    padding-right: 0.75em;
  }

  .box {
    box-shadow: none;

    .box__orange-txt p,
    .box__orange-txt a,
    .box__orange-txt span {
      color: ${colors.orange};
      font-weight: 500;
      display: inline-flex;
      align-items: baseline;
    }
  }

  .box__blue-bg .box {
    background-color: ${colors.blueLightOne};
  }

  .tags {
    flex-direction: column;
    align-items: baseline;

    .tag {
      border: 1px solid ${colors.blue};
      background-color: ${colors.blueLightOne};
    }
  }
`
